import { defineStore } from "pinia";

import type { Category } from "~/types";

export const useFilteringStore = defineStore("filtering", {
  state: () => ({
    userClaimState: [] as ("isMarked" | "isNew")[],
    categories: [] as Category[],
    scope: "all" as "all" | "editorial" | "community"
  }),
  actions: {
    reset() {
      this.userClaimState = [];
      this.categories = [];
      this.scope = "all";
    },
    addCategory(category: Category) {
      this.categories.push(category);
    },
    removeCategory(categoryName: string) {
      this.categories = this.categories.filter((category) => category.name !== categoryName);
    }
  },
  getters: {
    getIsMarked: (state) => () => state.userClaimState.includes("isMarked"),
    getIsNew: (state) => () => state.userClaimState.includes("isNew")
  }
});
